<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Подключение Yandex Metrika
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-overlay
              v-if="metrikaTokens"
              :show="saveLoading"
              rounded="sm"
            >
              <b-row>
                <b-col cols="4">
                  <span
                    v-if="countersOptions.length != 0"
                    class="mb-5"
                  >
                    <b>
                      Найдено {{ countersOptions.length }} счётчиков
                    </b>
                  </span>
                  <v-select
                    id="item-name-1222"
                    v-model="counter"
                    :options="countersOptions"
                    class="mb-1 mt-1"
                    label="text"
                    :loading="countersLoading"
                    placeholder="Найдите необходимый логин..."
                  >
                    <template #no-options="{ search, searching, loading }">
                      Не найдено.
                    </template>
                  </v-select>
                  <transition
                    name="fade"
                    mode="out-in"
                  >
                    <b-col
                      v-if="counter != null"
                      cols="12"
                    >
                      <div
                        class="mt-1"
                        style="display: inline-flex;"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          size="sm"
                          :disabled="isEcommerce"
                          @click="getGoalsByCounterId"
                        >
                          Получить цели счетчика
                        </b-button>
                        <span class="ml-1">или</span>
                        <b-form-checkbox
                          v-model="isEcommerce"
                          class="ml-1"
                          value="A"
                        >
                          Проект является E-commerce?
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </transition>
                </b-col>

                <transition
                  name="fade"
                  mode="out-in"
                >
                  <b-col
                    v-if="counter != null && !isEcommerce"
                    cols="8"
                  >
                    <h5>Цели</h5>
                    <b-table
                      :data="goalsData"
                      :checked-rows.sync="checkedRows"
                      :paginated="true"
                      :loading="goalsTableLoading"
                      checkable
                      class="mt-1"
                      :checkbox-position="checkboxPosition"
                    >
                      <b-table-column
                        v-slot="props"
                        searchable
                        field="name"
                        label="Название"
                      >
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column
                        v-slot="props"
                        field="type"
                        label="Тип"
                      >
                        {{ mapTypes(props.row.type) }}
                      </b-table-column>
                      <template #bottom-left>
                        <b>Выбрано целей</b>: {{ checkedRows.length }}
                      </template>
                    </b-table>
                  </b-col>
                </transition>
              </b-row>
              <b-col cols="4">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  variant="outline-primary"
                  @click="saveIntegration()"
                >
                  Сохранить выбор
                </b-button>
              </b-col>
            </b-overlay>
            <b-col
              v-else
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="redirectYandexOAuth()"
              >
                <b-spinner
                  v-if="showRedirect"
                  small
                />
                Перенаправить на oauth.yandex.ru
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BSpinner,
  BCardTitle,
  BFormCheckbox,
  BCol,
  BRow,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'

vSelect.props.components.default = () => ({ OpenIndicator })

const YANDEX_DIRECT_AUTH_URL = 'https://oauth.yandex.ru/authorize?response_type=code&force_confirm=true'

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BCardHeader,
    BCardBody,
    BOverlay,
    BSpinner,
    BFormCheckbox,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    connectionId: [String, Number],
    metrikaTokens: {
      type: [Object],
      required: false,
    },
  },
  data() {
    return {
      showRedirect: false,
      goalsTableLoading: false,
      columns: [{
        field: 'name',
        label: 'Название',
      },
      {
        field: 'type',
        label: 'Тип',
      }],
      countersOptions: [],
      goalsData: [],
      counter: null,
      checkedRows: [],
      checkboxPosition: 'left',
      countersLoading: false,
      saveLoading: false,
      isEcommerce: false,
    }
  },
  computed: {
    mapTypes() {
      const statusLabel = {
        number: 'Количество просмотров',
        action: 'JavaScript-событие',
        step: 'Составная цель',
        url: 'Совпадение по URL страницы',
        email: 'Клик по email',
        payment_system: 'Платежные системы',
        phone: 'Клик по номеру телефона',
        form: 'Отправка формы',
        conditional_call: 'Звонок',
        call: 'Звонок',
        messenger: 'Переход в мессенджер',
        button: 'Клик по кнопке',
        file: 'Скачивание файла',
        search: 'Поиск по сайту',
      }
      return type => statusLabel[type]
    },
  },
  watch: {
    counter() {
      this.goalsData = []
      this.checkedRows = []
    },
    isEcommerce() {
      this.goalsData = []
      this.checkedRows = []
    },
  },
  mounted() {
    if (this.metrikaTokens != null
        && this.metrikaTokens.accessToken != null) {
      this.$http.get(`/yandex-metrika/counters?token=${this.metrikaTokens.accessToken}`)
        .then(response => {
          const dataForParse = response.data.response
          dataForParse.counters.forEach(element => this.countersOptions.push({
            // TO-DO: NEED refactoring
            value: element.id,
            text: element.name,
          }))
        })
    }
  },
  created() {
  },
  methods: {
    clearData() {
      this.goalsData = []
    },
    getGoalsByCounterId() {
      this.$http.get(`/yandex-metrika/goals?counterId=
        ${this.counter.value}&token=${this.metrikaTokens.accessToken}`)
        .then(response => {
          const dataForParse = response.data.result.goals
          this.goalsData = dataForParse
        })
    },
    redirectYandexOAuth() {
      this.showRedirect = true
      // TO-DO: NEED refactoring
      function removeSpaces(url) {
        return encodeURI(decodeURI(url).replace(/\s+/g, ''))
      }

      const redirectUri = encodeURI(`${window.location.origin}/apply-yandex-metrika-code`)

      window.location.href = removeSpaces(`${YANDEX_DIRECT_AUTH_URL}
        &client_id=afa7e217cc014728968039a0d968e7a7
        &state=${this.$route.params.connectionId}
        &redirect_uri=${redirectUri}`)
    },
    saveIntegration() {
      const connId = this.$route.params.connectionId

      // TO-DO: NEED refactoring
      const obj = {
        refreshToken: this.metrikaTokens.refreshToken,
        accessToken: this.metrikaTokens.accessToken,
        accessTokenExpirationDate: this.metrikaTokens.accessTokenExpirationDate,
        connectionId: connId,
        type: 'YANDEX_METRIKA',
        info: JSON.stringify({
          counterId: this.counter.value,
          goals: this.checkedRows,
          isEcommerce: this.isEcommerce,
        }),
      }
      this.saveLoading = true
      this.$http.post('integration/fill', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$router.push({
            name: 'new-connection',
            params: { connectionId: connId },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-table::v-deep .pagination-list {
  margin-top: 20px!important;
}

.no-content {
  margin:0 auto;
}
</style>
